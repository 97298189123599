import axios from 'axios'
import {
    Message
} from 'element-ui'
// import { Toast } from 'vant';
// import store from '@/store/index';
// import router from '@/router'

// let url = 'http://baipubang.xxzcloud.com';
// let url = 'https://ht.baipubang.com';
let url = 'https://pt.baipubang.com';
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = url;
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = url;
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = url;
}
axios.defaults.timeout = 60000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = localStorage.getItem("token")
        // token && (config.headers.Authorization = token)
        if (config.method == "post") {
            token && (config.headers.token = token)
        }
        return config
    },
    error => {
        return Promise.error(error)
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            if (response.status == 202) {
                Message({
                    message: response.data.msg,
                    type: 'error'
                })
                localStorage.removeItem('uid')
                localStorage.removeItem('userInfo')
                localStorage.removeItem('user_type')
                // setTimeout(() => {
                    // window.history.back()
                // }, 1500);
            }
            return Promise.reject(response)
        }
    },
    // 服务器状态码不是200的情况
    error => {
        console.log(error.response.status)
        if (error.response.status) {
            // switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                // case 201:
                    // router.replace({
                    //     path: '/login',
                    //     query: { redirect: router.currentRoute.fullPath }
                    // })
                    // Toast.fail(error.response.data.msg);
                    // localStorage.removeItem('uid')
                    // return Promise.reject(error.response)
                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                // case 402:
                    // router.replace({
                    //     path: '/login',
                    //     query: { redirect: router.currentRoute.fullPath }
                    // })
                    // Toast.fail(error.response.data.msg);

                    // localStorage.removeItem('token')
                    // store.commit('outLogin')
                    // break
                // case 403:
                    // Toast.fail(error.response.data.msg);
                    // localStorage.removeItem('token')
                    // store.commit('loginSuccess', null)
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    // setTimeout(() => {
                        // router.replace({
                        //     path: '/login',
                        //     query: {
                        //         redirect: router.currentRoute.fullPath
                        //     }
                        // })
                    // }, 1000)
                    // break
                    // 404请求不存在
                // case 404:
                    // Toast.fail('请求不存在');
                    // break
                    // 其他错误，直接抛出错误提示
                // default:
                    // Toast.fail(error.response.data.msg);
                    // return Promise.reject(error.response)
            // }
            return Promise.reject(error.response)
        }
    }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)

        })
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data)
                // loadingInstance1.close();
            })
            .catch(error => {
                reject(error.data)
                // loadingInstance1.close();
            })

    })
}