import { get, post } from './request.js'
// export const restapi = p => get('https://restapi.amap.com/v3/weather/weatherInfo', p)   //首页
export const downs  = p => get('/api/index/downs', p) //下载
export const index = p => post('/api/sell/index', p) //首页
export const lease_list_one = p => post('/api/sell/lease_list_one', p) //转让列表
export const lease_list_two = p => post('/api/sell/lease_list_two', p) //出租列表
export const ivn_sale_list_one = p => post('/api/sell/ivn_sale_list_one', p) //招商列表
export const ivn_sale_list_two = p => post('/api/sell/ivn_sale_list_two', p) //出售列表
export const find_shop_list = p => post('/api/sell/find_shop_list', p) //找店列表
export const old_goods_list = p => post('/api/sell/old_goods_list', p) //二手货品转让
export const acquisition_list = p => post('/api/sell/acquisition_list', p) //二手货品收购
export const classify_list = p => post('/api/sell/classify_list', p) //分类列表
export const old_goods_val = p => post('/api/sell/old_goods_val', p) //二手货品转让详情
export const similar_goods = p => post('/api/sell/similar_goods', p) //二手货品转让类似推荐
export const acquisition_val = p => post('/api/sell/acquisition_val', p) //二手货品收购类似推荐
export const send_old_goods_sub = p => post('/api/sell/send_old_goods_sub', p) //二手出售发布
export const send_acquisition_sub = p => post('/api/sell/send_acquisition_sub', p) //二手收购发布
export const lease_val = p => post('/api/sell/lease_val', p) //转让出租详情
export const ivn_sale_val = p => post('/api/sell/ivn_sale_val', p) //招商出售详情
export const find_shop_val = p => post('/api/sell/find_shop_val', p) //找店详情
export const similar_shop = p => post('/api/sell/similar_shop', p) //转让出租类似推荐
export const similar_find = p => post('/api/sell/similar_find', p) //找店选址类似推荐
export const add_collect = p => post('/api/user/add_collect', p) //添加收藏
export const del_collect = p => post('/api/user/del_collect', p) //取消收藏
export const user_core = p => post('/api/user/user_core', p) //个人信息
export const my_send = p => post('/api/user/my_send', p) //个人发布信息
export const meet_shop_list = p => post('/api/user/meet_shop_list', p) //个人接店信息
export const user_collect = p => post('/api/user/user_collect', p) //个人收藏信息
export const been_the_store = p => post('/api/user/been_the_store', p) //接店用户
export const transfer_out = p => post('/api/user/transfer_out', p) //确认转
export const cancel_send = p => post('/api/user/cancel_send', p) //取消发布
export const cancel_take = p => post('/api/sell/cancel_take', p) //取消接店
export const instructions = p => post('/api/sell/instructions', p) //发布须知
export const salesman_list = p => post('/api/sell/salesman_list', p) //商务经理
export const send_view = p => post('/api/sell/send_view', p) //发布信息
export const city_area_list = p => post('/api/sell/city_area_list', p) //开放地区
export const send_lease_sub = p => post('/api/sell/send_lease_sub', p) //发布转让出租
export const send_ivn_sale_sub = p => post('/api/sell/send_ivn_sale_sub', p) //发布招商/出售
export const send_find_shop_sub = p => post('/api/sell/send_find_shop_sub', p) //发布找店
export const use_coupons = p => post('/api/sell/use_coupons', p) //优惠价
export const select_card = p => post('/api/sell/select_card', p) //套餐
export const select_ad_view = p => post('/api/sell/select_ad_view', p) //发布信息
export const select_ad_sub = p => post('/api/sell/select_ad_sub', p) //发布广告提交
export const pc_pay = p => post('/api/pc/pc_pay', p) //付费
export const get_is_pay = p => post('/api/pc/get_is_pay', p) //付费状态
export const save_drafts = p => post('/api/sell/save_drafts', p) //草稿
export const mer_info = p => post('/api/sell/mer_info', p) //关于
export const take_over = p => post('/api/sell/take_over', p) //确定接店
// export const city_list = p => post('/api/sell/city_list', p) //切换城市列表
export const city_list = p => post('/api/three/open_city', p)
export const edit_merchants_apply_view = p => post('/api/user/edit_merchants_apply_view', p) //商家入驻修改
export const my_apply = p => post('/api/user/my_apply', p) //申请商家入驻是否通过
export const getPlatformClass = p => post('/api/pub/getPlatformClass', p) //获取商家入驻分类
export const merchants_apply_sub = p => post('/api/user/merchants_apply_sub', p) //申请提交商家入驻审核
export const edit_merchants_apply_sub = p => post('/api/user/edit_merchants_apply_sub', p) //修改申请提交商家入驻审核
export const city_partner = p => post('/api/user/city_partner', p) //合伙人申請信息
export const edit_partner_sub = p => post('/api/user/edit_partner_sub', p) //合伙人編輯信息提交
export const edit_partner_view = p => post('/api/user/edit_partner_view', p) //合伙人編輯信息
export const partner_apply_view = p => post('/api/user/partner_apply_view', p) //合伙人级别
export const partner_apply_sub = p => post('/api/user/partner_apply_sub', p) //申请提交合伙人
export const agents_apply_view = p => post('/api/user/agents_apply_view', p) //代理修改信息
export const city_agents = p => post('/api/user/city_agents', p) //代理审核结果
export const agents_sub = p => post('/api/user/agents_sub', p) //申请提交代理
export const login = p => post('/api/pc/login', p) //登录 
export const send_code = p => post('/api/pc/send_code', p) //发送手机验证码
export const logout = p => post('/api/pc/logout', p) //退出登录
export const report_shop = p => post('/api/sell/report_shop', p) //举报
export const create_code = p => post('/api/pc_login/create_code', p) //获取微信登录二维码
export const get_add = p => post('/api/sell/get_add', p) //根据经纬度返回地理位置
export const select_login = p => post('/api/pc_login/select_login', p) //根据经纬度返回地理位置
export const agreement_look = p => post('/api/sell/agreement_look', p) //约看店
export const lease_list = p => post('/api/sell/lease_list', p) //推荐列表
export const ad_suspend = p => post('/api/sell/ad_suspend', p) //暂停
export const take_down = p => post('/api/user/take_down', p) //下架
export const cancel_suspend = p => post('/api/sell/cancel_suspend', p) //开启
export const take_up = p => post('/api/user/take_up', p) //上架
export const edit_drafts_view = p => post('/api/sell/edit_drafts_view', p) //获取转让出租编辑详情
export const edit_drafts_sub = p => post('/api/sell/edit_drafts_sub', p) //编辑提交转让出租
export const edit_ref_time_sub = p => post('/api/sell/edit_ref_time_sub', p) //编辑提交转让出租
export const set_grey = p => post('/api/sell/set_grey', p) //判断置灰
export const ad_one = p => post('/api/pc/ad_one', p) //头版广告一
// export const ad_two = p => post('/api/pc/ad_two', p) //头版广告二
// export const ad_three = p => post('/api/pc/ad_three', p) //优选广告
export const ad_two = p => post('/api/sell/index_pc_front_page', p) //头版广告二
export const ad_three = p => post('/api/sell/index_pc_preferred', p) //优选广告

export const index_pc_sharp_turn = p => post('/api/sell/index_pc_sharp_turn', p) //首页pc急转列表
export const index_pc_hot = p => post('/api/sell/index_pc_popular', p) //首页pc热门列表
export const index_pc_featured = p => post('/api/sell/index_pc_featured', p) //首页pc精选列表
// export const category_ad = p => post('/api/three/category_ad', p) //分类有广告/无广告列表
export const category_ad = p => post('api/three/new_category_ad', p) //分类有广告/无广告列表

export const home_index = p => post('/api/food/home_index', p) //公共

export const get_city_val = p => post('/api/three/get_city_val', p) //PC不同城市电话/地址/二维码内容
export const get_pc_val = p => post('/api/three/pc_val', p) //PC站固定参数
export const get_consultant_list = p => post('/api/three/consultant_list', p)//顾问电话/二维码
export const get_index_office = p => post('/api/three/index_office_building', p)//PC 首页写字楼
export const merchant_comment = p => post('/api/three/merchant_comment', p)//已成交获取商家评论

export const configParams = p => post('/api/pub/configParams', p) //配置












